import axios from "../../axios";
const authExport = {
  userLogin(email, password) {
    return axios.post("/user_login", {
      email,
      password,
    });
  },
  signUp(params) {
    return axios.post("/user_register", params);
  },
  verifyMobileOTP(params) {
    return axios.post("/verify_otp", params);
  },
  verifyEmailOTP(params) {
    return axios.post("/email_verify", params);
  },
  verifyEmailToken(token) {
    return axios.post("/email_verification_token", { token });
  },
  resendOTP(params) {
    return axios.post("/resend_otp", params);
  },
  resendEmailOTP(params) {
    return axios.post("/resend_email_otp", params);
  },
  forgotPassword(email) {
    return axios.post("/user_forgot_password", { email });
  },
  resetPassword(params) {
    return axios.post("/user_reset_password", params);
  },
  validateToken(token = null) {
    return axios.post("/users/validate-token", { accessToken: token });
  },
  unsubscribe(params) {
    return axios.post("/unsubscribe", params);
  },
};
export default authExport
