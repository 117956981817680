import React from "react";
import PropTypes from "prop-types";
import SocialLink from "./SocialLink";
import NavigationColumn from "./NavigationColumn";

import {
  Container,
  SocialIconsContainer,
  Wrapper,
  ContactLink,
  Expander,
  SafeAppWrapper,
  Description,
} from "./Footer.style";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import NewWhiteLogo from "@cashremit/logo/logos/NewWhiteLogo";
import { Grid as ReactGrid } from "react-flexbox-grid";

const Footer = ({
  columnOneLinks,
  columnOneTitle,
  columnTwoLinks,
  columnTwoTitle,
  columnThreeLinks,
  columnThreeTitle,
  phone,
  email,
  socialLinks,
  contactTitle,
  children,
}) => {
  const navigationColumns = [
    {
      title: columnOneTitle,
      links: columnOneLinks,
    },
    // {
    // 	title: columnTwoTitle,
    // 	links: columnTwoLinks,
    // },
    {
      title: columnThreeTitle,
      links: columnThreeLinks,
    },
  ].filter(({ title, links }) => title && links);

  const iosimage =
    "https://cashremitlogo.s3-us-west-2.amazonaws.com/Image+16.svg";
  const androidimage =
    "https://cashremitlogo.s3-us-west-2.amazonaws.com/Image+15.svg";

  return (
    <Wrapper>
      <SafeAppWrapper>{children}</SafeAppWrapper>

      <ReactGrid>
        <Grid templateColumns="repeat(12, 1fr)" gap={6} rowGap={20}>
          <GridItem colStart={{ base: 1, lg: 1 }} colEnd={{ base: 13, lg: 6 }}>
            <Box
              sx={{
                display: "flex",
                w: "100%",
                flexDirection: "column",
              }}
              alignItems={{ base: "center", lg: "flex-start" }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  mb: "32px",
                  svg: { w: "auto" },
                }}
              >
                <NewWhiteLogo />
              </Box>

              <Description>
                Cashremit Pty Ltd is a designated remittance provider registered
                with the Australian Transaction Report and Analysis
                Centre("AUSTRAC") We're regulated by the Australian
              </Description>

              <Box sx={{ display: "flex", flexFlow: "column" }}>
                <Heading
                  as="h5"
                  sx={{
                    fontWeight: 600,
                    color: "#fff",
                    mb: "16px",
                    textTransform: "uppercase",
                  }}
                  fontSize={{
                    base: "18px",
                  }}
                >
                  Download App
                </Heading>

                <Box sx={{ display: "flex", gap: 16 }}>
                  <a
                    href="https://apps.apple.com/au/app/cashremit-money-transfer/id1213282431"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={iosimage} alt={"iosimage"} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.cashremit.cashremitapp&hl=en_AU&gl=US"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={androidimage} alt={"androidimage"} />
                  </a>
                </Box>
              </Box>
            </Box>
          </GridItem>

          <GridItem colStart={{ base: 1, lg: 6 }} colEnd={{ base: 13, lg: 13 }}>
            <Box
              sx={{
                display: "flex",
                w: "100%",
                justifyContent: "center",
                flexDirection: "column",
              }}
              paddingLeft={{ base: 0, lg: 10 }}
            >
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
                {navigationColumns.map((column, index) => {
                  const showBorder = index !== navigationColumns.length - 1;
                  return (
                    column.title &&
                    column.links.length && (
                      <NavigationColumn
                        showBorder={showBorder}
                        key={`footer-nav-${index}`}
                        title={column.title}
                        links={column.links}
                        dataKey={`footer-nav-${index}`}
                      />
                    )
                  );
                })}

                <Container>
                  <Expander>{contactTitle}</Expander>
                  {phone && (
                    <ContactLink>
                      <a href={`tel://${phone}`}>{phone}</a>
                    </ContactLink>
                  )}
                  {email && (
                    <ContactLink>
                      <a href={`mailto://${email}`}>{email}</a>
                    </ContactLink>
                  )}

                  <SocialIconsContainer>
                    {socialLinks &&
                      socialLinks.map((link) => (
                        <SocialLink
                          key={`footer-social-${link.title}`}
                          link={link}
                        />
                      ))}
                  </SocialIconsContainer>
                </Container>
              </SimpleGrid>

              <Box sx={{ display: "flex", w: "100%", mt: "40px" }}>
                <Heading
                  as="h5"
                  sx={{
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                  fontSize={{
                    base: "15px",
                  }}
                >
                  Securities and Investments Commission(ASIC), Australian
                  Company Number (ACN) 612 129 576
                </Heading>
              </Box>
            </Box>
          </GridItem>
        </Grid>

        <Box
          sx={{
            display: "flex",
            w: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid rgba(255, 255, 255, 0.24)",
            mt: 8,
          }}
        >
          <Heading
            as="h5"
            sx={{
              fontWeight: 500,
              color: "rgba(255, 255, 255, 0.8)",
              py: 8,
            }}
            fontSize={{
              base: "14px",
            }}
          >
            © Cashremit Pty Limited 2016. All Right Reserved
          </Heading>
        </Box>
      </ReactGrid>

      {/* <FooterBottom>
        <FBottomtext>
          Securities and Investments Commission(ASIC), Australian Company Number
          (ACN) 612 129 576
        </FBottomtext>
        <FBottomtext>
          © <span>Cashremit</span> Pty Limited 2016. All Right Reserved
        </FBottomtext>
      </FooterBottom> */}
    </Wrapper>
  );
};

Footer.propTypes = {
  contactTitle: PropTypes.string,
  phone: PropTypes.node,
  email: PropTypes.node,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  columnOneLinks: PropTypes.arrayOf(PropTypes.node),
  columnOneTitle: PropTypes.string.isRequired,
  columnTwoLinks: PropTypes.arrayOf(PropTypes.node),
  columnTwoTitle: PropTypes.string,
  columnThreeLinks: PropTypes.arrayOf(PropTypes.node),
  columnThreeTitle: PropTypes.string,
};
export default Footer;
