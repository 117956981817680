import axios from "../axios";

const countryExport = {
  getCountryList() {
    return axios.get("/country"); // Get All Active Country for Currency Transfer
  },
  getCountries() {
    return axios.get("/countries"); // All Country List
  },
  currencyConverter(params = {}) {
    return axios.post("/currency_converter", params);
  },
  getCountryByCode(code) {
    return axios.get(`/country/${code}`);
  }
};
export default  countryExport
