import axios from "../axios";

const rateChangeExport = {
  getrateChangeHistory({from, to, from_country_id, to_country_id}) {
    return axios.get(
      `/users/rate-change/history?from=${from}&to=${to}&from_country_id=${from_country_id}&to_country_id=${to_country_id}`
    );
  },
};
export default rateChangeExport
