import axios from "../axios";

const transactionExport = {
  getTransactionList(params) {
    return axios.post("/users/transactions", params);
  },
  getTransactionDetail(id) {
    return axios.get(`/users/transactions/${id}`);
  },
  getTransactionFullDetail(id) {
    return axios.get(`/users/transactions/${id}/detail`);
  },
  cancelTransaction(id) {
    return axios.put(`/users/transactions/cancel/${id}`);
  },
  getCurrencyConverterData(params) {
    return axios.post("/users/transactions/currency_converter_lite", params);
  },
};

export default transactionExport