import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Navigation,
  Toggle,
  Navbox,
  Hamburger,
  LogoWrapWeb,
  NavItem,
  NavWrapper,
  SigupBTN,
  LogoWrapMobile,
} from "./Topnav.style";
import { Grid } from "react-flexbox-grid";
import { Box, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import MenuDropDownLink from "../../@cashremit/TopNav/MenuDropdown";
import NewLogo from "@cashremit/logo/logos/NewLogo";

const Topnav = ({
  isBgColor,
  links,
  user,
  auth_links,
  isBgWhite = false,
  homepage = "/",
}) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
      <Navigation>
        <Grid margin="0">
          <>
            <Box
              sx={{ w: "100%", position: "relative", py: 4 }}
              display={{ base: "flex", lg: "none" }}
            >
              <LogoWrapMobile>
                <a href={homepage}>
                  {/* <Logo type="whitebg" size="lg" /> */}

                  <NewLogo />

                  {/* <Image src="/assets/newlogo.png" alt="logo" /> */}
                </a>
              </LogoWrapMobile>

              <Toggle
                navbarOpen={navbarOpen}
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                {navbarOpen ? (
                  <Hamburger open isBgColor={isBgColor} />
                ) : (
                  <Hamburger isBgColor={isBgColor} />
                )}
              </Toggle>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", w: "100%" }}>
              <LogoWrapWeb>
                <a href={homepage}>
                  {/* {isBgWhite ? (
                    <Logo type="brandbg" size="lg" />
                  ) : (
                    <Logo type="whitebg" size="lg" />
                  )} */}

                  {/* <Logo type="whitebg" size="lg" /> */}

                  <Image
                    src="/assets/newlogo.png"
                    alt="logo"
                    objectFit="contain"
                    sx={{ height: "36px" }}
                  />

                  {/* <NewLogo /> */}
                </a>
              </LogoWrapWeb>

              {navbarOpen ? (
                <Navbox>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "34px",
                    }}
                  >
                    <NavWrapper>
                      <MenuDropDownLink />

                      {links.map((link, index) => (
                        <NavItem
                          isBgColor={isBgColor}
                          to={link.href}
                          key={index}
                        >
                          <Link key={index} to={link.href}>
                            {link.title}
                          </Link>
                        </NavItem>
                      ))}
                    </NavWrapper>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <NavWrapper>
                      {!user && (
                        <NavItem isBgColor={isBgColor} to={"/login"}>
                          <Link to={"/login"}>Login</Link>
                        </NavItem>
                      )}

                      {auth_links.map((link, index) => (
                        <SigupBTN to={link.href} key={index}>
                          <Link key={index} to={link.href}>
                            {link.title}
                          </Link>
                        </SigupBTN>
                      ))}
                    </NavWrapper>
                  </Box>
                </Navbox>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", w: "100%" }}>
                  <Navbox open>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "34px",
                      }}
                    >
                      <NavWrapper>
                        <MenuDropDownLink />

                        {links.map((link, index) => (
                          <NavItem
                            isBgColor={isBgColor}
                            to={link.href}
                            key={index}
                          >
                            <Link key={index} to={link.href}>
                              {link.title}
                            </Link>
                          </NavItem>
                        ))}
                      </NavWrapper>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <NavWrapper>
                        {!user && (
                          <NavItem isBgColor={isBgColor} to={"/login"}>
                            <Link to={"/login"}>Login</Link>
                          </NavItem>
                        )}

                        {auth_links.map((link, index) => (
                          <SigupBTN to={link.href} key={index}>
                            <Link key={index} to={link.href}>
                              {link.title}
                            </Link>
                          </SigupBTN>
                        ))}
                      </NavWrapper>
                    </Box>
                  </Navbox>
                </Box>
              )}
            </Box>

            {/* <Toggle
            navbarOpen={navbarOpen}
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? (
              <Hamburger open isBgColor={isBgColor} />
            ) : (
              <Hamburger isBgColor={isBgColor} />
            )}
          </Toggle> */}

            {/* {navbarOpen ? (
            <>
              <Navbox>
                <NavbarLinks
                  links={links}
                  auth_links={auth_links}
                  isBgColor={isBgColor}
                  user={user}
                />
              </Navbox>
            </>
          ) : (
            <>
              <Navbox open>
                <NavbarLinks
                  links={links}
                  auth_links={auth_links}
                  isBgColor={isBgColor}
                  user={user}
                />
              </Navbox>
            </>
          )} */}
          </>
        </Grid>
      </Navigation>
    </>
  );
};

Topnav.propTypes = { isBgWhite: PropTypes.bool };

export default Topnav;
