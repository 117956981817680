import React from "react";

const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const AddFunds = React.lazy(() => import("./components/AddFunds"));
const SendMoney = React.lazy(() => import("./components/SendMoney/SendMoney"));
const CallbackPage = React.lazy(() =>
  import("./components/SendMoney/CallbackPage")
);
const Transactions = React.lazy(() =>
  import("./components/Transactions/Transactions")
);
const Beneficiary = React.lazy(() =>
  import("./components/Beneficiary/Beneficiary")
);
const NeedHelp = React.lazy(() => import("./components/NeedHelp/NeedHelp"));
const VirtualBank = React.lazy(() => import("./components/VirtualBank/VirtualBank"));
const InviteFriend = React.lazy(() =>
  import("./components/InviteFriend/InviteFriend")
);
const AccountSettings = React.lazy(() =>
  import("./components/AccountSettings/AccountSettings")
);
const DocumentationVerification = React.lazy(() =>
  import("./components/AccountSettings/DocumentationVerification")
);
const Cashpoint = React.lazy(()=> import("./components/CashPoint/Cashpoint"));

const clientRoutes = [
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/add-funds",
    exact: true,
    name: "Add Funds",
    component: AddFunds,
  },
  {
    path: "/send-money",
    exact: true,
    name: "Send Money",
    component: SendMoney,
  },
  {
    path: "/poli/callback",
    exact: true,
    name: "Poli Callback",
    component: CallbackPage,
    content: {
      kaasi: false,
    },
  },
  {
    path: "/kaasi/callback",
    exact: true,
    name: "Kaasi Callback",
    component: CallbackPage,
    content: {
      kaasi: true,
    },
  },
  {
    path: "/transactions",
    exact: true,
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/beneficiary",
    exact: true,
    name: "Beneficiary",
    component: Beneficiary,
  },
  {
    path: "/need-help",
    exact: true,
    name: "Need Help",
    component: NeedHelp,
  },
  // {
  //   path: "/virtual-bank",
  //   exact: true,
  //   name: "Virtual Bank",
  //   component: VirtualBank,
  // },
  {
    path:"/cash-point",
    exact: true,
    name: "CashPoint",
    component: Cashpoint
  },
  {
    path: "/invite-friend",
    exact: true,
    name: "Invite Friend",
    component: InviteFriend,
  },
  {
    path: "/settings",
    exact: true,
    name: "Account Settings",
    component: AccountSettings,
  },
  {
    path: "/document-verification",
    exact: true,
    name: "Document Verification",
    component: DocumentationVerification,
  },
];

export default clientRoutes;
