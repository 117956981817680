import axios from "../axios";

const notificationExport = {
	getNotificationList() {
		return axios.get("/users/notification/unread");
	},
	markAsReadNotification(id) {
		return axios.put("/users/notification/markAsRead", { id });
	}
};

export default notificationExport