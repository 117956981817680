import axios from "../axios";

const virtualbankExport = {
  getCurrancyList() {
    return axios.get("/users/get-bank-balances");
  },
  getVirtualData(id) {
    return axios.get(`/users/get-virtual-data/${id}`);
  },
  createVirtualBank(payload) {
    return axios.post("/users/create-virtual-bank-store", payload);
  },
  getVirtualDataByRefs(id) {
    return axios.get(`/users/get-virtual-bank-store/${id}`);
  },
  createCreditVirtualBank(payload) {
    return axios.post("/users/credit-virtual-bank", payload);
  },
  // createAudBalance(payload){
  //   return axios.post("/users/credit-aud-balance",payload)
  // }
  // createAudBalance(id) {
  //   return axios.get(`/admin/users/${id}/cr-wallet-details`);
  // },
  createNgnBalance(id) {
    return axios.get(`/users/create-store-virtual-transactions/${id}`);
  },
  getCashPointbrands(){
    return axios.get("/users/cashpoint-brands");
  },
  getCashPointDeals(){
    return axios.get("/users/cashpoint-deals");
  }
};

export default virtualbankExport;
