import React from "react";
import PropTypes from "prop-types";

import {
  AppDemoWrapper,
  Link,
  AppDemoImg,
  AppIMGWrapper,
  LinkWrapper,
} from "./style";
import { Grid } from "react-flexbox-grid";
import { Box, Heading, Image, SimpleGrid, Text } from "@chakra-ui/react";
import TransferSvg from "assets/svg/TransferSvg";
import RequestSvg from "assets/svg/RequestSvg";
// import internationalTransfer from "/assets/international-transfer.png";

const iosimage =
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/Image+16.svg";
const androidimage =
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/Image+15.svg";

const AppScreen = ({ appdemo, heading, title, subtitle }) => {
  return (
    <>
      {/* <AppDemoWrapper>
        <AppWrapper>
          <AppDemoImg src={appdemo} alt={AppScreen} />
          <ContentWrapper>
            <ArrowImage src="/assets/img/app-download-arrow.png" />
            <AppHeading>{heading || ""}</AppHeading>
            <AppTitle>{title}</AppTitle>
            <AppSubTitle>{subtitle}</AppSubTitle>
            <LinkWrapper>
              <Link href="https://apps.apple.com/au/app/cashremit-money-transfer/id1213282431">
                <AppIMGWrapper src={iosimage} alt="bank" />
              </Link>
              <Link href="https://play.google.com/store/apps/details?id=com.cashremit.cashremitapp&hl=en_AU&gl=US">
                <AppIMGWrapper href="/" src={androidimage} alt="bank" />
              </Link>
            </LinkWrapper>
          </ContentWrapper>
        </AppWrapper>
      </AppDemoWrapper> */}

      <AppDemoWrapper>
        <Grid>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacingX="40px"
            spacingY="20px"
          >
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              alignItems={{ base: "center", md: "flex-start" }}
            >
              <Heading
                as="h5"
                fontSize={{ base: "18px", md: "20px" }}
                sx={{
                  color: "#305178",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  mb: "28px",
                }}
              >
                Cashremit mobile app
              </Heading>
              <Heading
                as="h2"
                fontSize={{ base: "28px", md: "28px", xl: "56px" }}
                sx={{
                  color: "#305178",
                  fontWeight: 700,
                  textTransform: "uppercase",
                  mb: "28px",
                }}
                textAlign={{ base: "center", md: "left" }}
              >
                START YOUR INTERNATIONAL TRANSFER
              </Heading>
              <Text
                fontSize={{ base: "16px", md: "18px" }}
                sx={{ color: "#0F1F33", fontWeight: 500, mb: "60px" }}
                textAlign={{ base: "center", md: "left" }}
              >
                CashRemit Mobile App Cross-border payments platfrom, fast and
                easy
              </Text>

              <LinkWrapper>
                <Link href="https://apps.apple.com/au/app/cashremit-money-transfer/id1213282431">
                  <AppIMGWrapper src={iosimage} alt="bank" />
                </Link>
                <Link href="https://play.google.com/store/apps/details?id=com.cashremit.cashremitapp&hl=en_AU&gl=US">
                  <AppIMGWrapper href="/" src={androidimage} alt="bank" />
                </Link>
              </LinkWrapper>
            </Box>

            <Box sx={{ display: "inline-block", width: "100%" }}>
              <Box
                sx={{
                  display: "inline-block",
                  position: "relative",
                  w: "100%",
                }}
              >
                <AppDemoImg
                  src={"/assets/international-transfer.png"}
                  alt={AppScreen}
                />

                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexFlow: "column",
                    borderRadius: "8px",
                    background: "white",
                    padding: "25px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                    borderRight: "17px solid #D8E127",
                  }}
                  right={{ base: "20px", lg: "12px" }}
                  bottom={{ base: "20px", lg: "26px" }}
                >
                  <Heading
                    as="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#305178",
                      mb: "16px",
                    }}
                  >
                    Account Balance
                  </Heading>

                  <Heading
                    as="h4"
                    fontSize={{
                      base: "18px",
                      md: "24px",
                    }}
                    sx={{ fontWeight: 600, color: "#305178", mb: "30px" }}
                  >
                    $ 3,020.000
                  </Heading>

                  <Box
                    sx={{
                      display: "flex",
                      w: "100%",
                      alignItems: "center",
                      gap: 16,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        w: "100%",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <TransferSvg />
                      </Box>

                      <Box sx={{ display: "flex" }}>
                        <Text
                          sx={{
                            fontSize: "14px",
                            color: "#305178",
                            fontWeight: 600,
                          }}
                        >
                          Transfer
                        </Text>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        w: "100%",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <RequestSvg />
                      </Box>

                      <Box sx={{ display: "flex" }}>
                        <Text
                          sx={{
                            fontSize: "14px",
                            color: "#305178",
                            fontWeight: 600,
                          }}
                        >
                          Request
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                  }}
                  left={{ base: "unset", lg: "-12rem", xl: "-12rem" }}
                  bottom={{ base: "unset", lg: "-89px", xl: "-78px" }}
                  display={{ base: "none", lg: "inline-block" }}
                >
                  <Image
                    src="/assets/app-screenshot.png"
                    alt="app-screenshot"
                    boxSize={400}
                    objectFit={"contain"}
                  />
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </Grid>
      </AppDemoWrapper>
    </>
  );
};

AppScreen.propTypes = {
  downloadapp: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  mobiledemo: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default AppScreen;
