import axios from "../axios";

const walletExport = {
  getWalletBalance() {
    return axios.get("/users/wallet/balance");
  },
  getWalletBalanceCountryWise(currency) {
    return axios.get(`/users/wallet/balance/currency/${currency}`);
  },
  getWalletHistory(pageSize = 1, limit = 1) {
    return axios.get(
      `/users/wallet/transactions?pageSize=${pageSize}&limit=${limit}`
    );
  },
};
export default walletExport
