import React from "react";
import PropTypes from "prop-types";
import { Grid as ReactGrid} from "react-flexbox-grid";
import {
  Wrapper,
  TextAlign,
  AppScreenWrapper,
  TitleWrapper,
  H2,
  H4,
} from "./style";
import {
  Box,
  Grid,
  GridItem as ChakraGridItem,
  SimpleGrid,
  Heading,
  Text,
} from "@chakra-ui/react";
import LockSvg from "assets/svg/LockSvg";
import LikeSvg from "assets/svg/LikeSvg";
import StarSvg from "assets/svg/StarSvg";

// const secureIcon = () => (
//   <svg
//     width="50"
//     height="50"
//     viewBox="0 0 104 104"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <g clip-path="url(#clip0)">
//       <path
//         d="M91.8079 69.4505C89.0125 77.0264 84.785 83.6137 79.2411 89.0274C72.9307 95.1894 64.6677 100.086 54.6812 103.579C54.3535 103.693 54.0115 103.787 53.6679 103.856C53.2141 103.945 52.7523 103.994 52.2945 104H52.2048C51.7168 104 51.2265 103.951 50.7401 103.856C50.3965 103.787 50.0593 103.693 49.7332 103.581C39.7348 100.094 31.4614 95.1997 25.1447 89.0377C19.5985 83.624 15.3717 77.0407 12.5803 69.4647C7.50458 55.6903 7.7934 40.5163 8.02588 28.3224L8.02985 28.1351C8.07666 27.1282 8.10681 26.0706 8.12348 24.9026C8.20838 19.1683 12.7676 14.4242 18.5027 14.1044C30.4601 13.4371 39.7102 9.5373 47.6139 1.83362L47.6829 1.77015C48.9953 0.566473 50.6488 -0.0238593 52.2945 0.000737934C53.8814 0.0213679 55.462 0.610906 56.7275 1.77015L56.795 1.83362C64.7002 9.5373 73.9503 13.4371 85.9077 14.1044C91.6428 14.4242 96.202 19.1683 96.2869 24.9026C96.3036 26.0785 96.3338 27.1346 96.3806 28.1351L96.3829 28.2145C96.6146 40.4314 96.9019 55.6356 91.8079 69.4505Z"
//         fill="#D6DF22"
//       />
//       <path
//         d="M91.8078 69.4505C89.0124 77.0264 84.7849 83.6137 79.241 89.0275C72.9306 95.1895 64.6675 100.086 54.6811 103.579C54.3534 103.693 54.0114 103.787 53.6678 103.856C53.214 103.945 52.7522 103.994 52.2943 104V0.000732422C53.8813 0.0213624 55.4618 0.610901 56.7274 1.77014L56.7948 1.83362C64.7001 9.5373 73.9502 13.4371 85.9076 14.1044C91.6427 14.4242 96.202 19.1683 96.2869 24.9026C96.3035 26.0785 96.3337 27.1346 96.3805 28.1352L96.3829 28.2145C96.6146 40.4314 96.9018 55.6356 91.8078 69.4505Z"
//         fill="#C7D01C"
//       />
//       <path
//         d="M78.1222 52.0002C78.1222 66.261 66.5433 77.8693 52.2943 77.9201H52.2031C37.9121 77.9201 26.2832 66.2919 26.2832 52.0002C26.2832 37.7092 37.9121 26.0811 52.2031 26.0811H52.2943C66.5433 26.1318 78.1222 37.7401 78.1222 52.0002Z"
//         fill="white"
//       />
//       <path
//         d="M78.1223 52.0002C78.1223 66.261 66.5433 77.8693 52.2943 77.9201V26.0811C66.5433 26.1318 78.1223 37.7401 78.1223 52.0002Z"
//         fill="#E1EBF0"
//       />
//       <path
//         d="M63.9676 48.2673L52.2943 59.9423L49.7719 62.4647C49.176 63.0606 48.3945 63.3581 47.6137 63.3581C46.8321 63.3581 46.0514 63.0606 45.4547 62.4647L40.0314 57.039C38.8397 55.8472 38.8397 53.9168 40.0314 52.7242C41.2216 51.5324 43.1545 51.5324 44.3462 52.7242L47.6137 55.9917L59.6528 43.9525C60.8446 42.76 62.7774 42.76 63.9676 43.9525C65.1594 45.1443 65.1594 47.0772 63.9676 48.2673Z"
//         fill="#274972"
//       />
//       <path
//         d="M63.9676 48.2674L52.2943 59.9423V51.3103L59.6528 43.9525C60.8446 42.76 62.7775 42.76 63.9676 43.9525C65.1594 45.1443 65.1594 47.0772 63.9676 48.2674Z"
//         fill="#19385E"
//       />
//     </g>
//     <defs>
//       <clipPath id="clip0">
//         <rect width="104" height="104" fill="white" />
//       </clipPath>
//     </defs>
//   </svg>
// );

// const simpleIcon = () => (
//   <svg
//     width="50"
//     height="50"
//     viewBox="0 0 104 104"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <g clip-path="url(#clip0)">
//       <path
//         d="M69.9288 51.8252H43.5262C42.1502 51.8252 40.9445 50.9048 40.5817 49.5776C40.2189 48.2504 40.7889 46.8445 41.9733 46.1444C53.4769 39.347 59.4522 31.4178 60.2403 21.9038C60.5151 18.6021 63.456 15.9647 66.7944 16.0083C70.2008 16.0628 73.3921 17.4282 75.7822 19.8535C78.1742 22.2811 79.4925 25.4957 79.4937 28.905C79.4937 28.9054 79.4937 28.9058 79.4937 28.9062V29.9318C79.4937 38.2599 77.027 43.57 72.4756 50.4555C71.9099 51.3109 70.9537 51.8252 69.9288 51.8252Z"
//         fill="#FFC380"
//       />
//       <path
//         d="M102.286 55.2847C102.286 50.0108 97.9954 45.72 92.7213 45.72H43.5262C42.9796 45.72 42.443 45.8666 41.9727 46.1449C38.5194 48.1867 35.0326 49.8676 32.2309 51.2186C31.2536 51.6896 30.3306 52.1347 29.4911 52.5543C28.457 53.0715 27.8037 54.1283 27.8037 55.2847V94.3577C27.8037 95.514 28.457 96.5709 29.4911 97.0881C41.8255 103.254 52.0784 104 64.0345 104C65.886 104 67.7796 103.982 69.7267 103.964C71.8802 103.944 74.1067 103.922 76.441 103.922H86.0913C91.3652 103.922 95.656 99.6318 95.656 94.3577C95.656 92.5844 95.1712 90.9224 94.3266 89.4971C97.0746 87.8148 98.9121 84.7848 98.9121 81.3333C98.9121 79.56 98.4273 77.898 97.5827 76.4727C100.331 74.7904 102.168 71.7604 102.168 68.3089C102.168 65.8283 101.219 63.5653 99.6649 61.8641C101.357 60.0805 102.286 57.7541 102.286 55.2847Z"
//         fill="#FFCF99"
//       />
//       <path
//         d="M95.9366 28.6645H89.4244C87.7387 28.6645 86.3719 27.2976 86.3719 25.6119C86.3719 23.9262 87.7387 22.5593 89.4244 22.5593H95.9366C97.6224 22.5593 98.9892 23.9262 98.9892 25.6119C98.9892 27.2976 97.6226 28.6645 95.9366 28.6645Z"
//         fill="#29CCB1"
//       />
//       <path
//         d="M50.3514 28.6645H43.8392C42.1535 28.6645 40.7867 27.2976 40.7867 25.6119C40.7867 23.9262 42.1535 22.5593 43.8392 22.5593H50.3514C52.0372 22.5593 53.404 23.9262 53.404 25.6119C53.404 27.2976 52.0376 28.6645 50.3514 28.6645Z"
//         fill="#29CCB1"
//       />
//       <path
//         d="M60.1228 11.7459C59.0676 11.7459 58.0418 11.1985 57.4765 10.2192L54.2204 4.57948C53.3775 3.11962 53.8778 1.2525 55.3376 0.409729C56.7975 -0.433239 58.6646 0.0670576 59.5074 1.52692L62.7635 7.16668C63.6064 8.62654 63.1061 10.4937 61.6463 11.3364C61.1657 11.6141 60.6406 11.7459 60.1228 11.7459Z"
//         fill="#29CCB1"
//       />
//       <path
//         d="M79.6533 11.7459C79.1353 11.7459 78.6105 11.614 78.1299 11.3364C76.67 10.4934 76.1697 8.62648 77.0127 7.16662L80.2688 1.52685C81.1116 0.066994 82.9787 -0.433709 84.4385 0.409666C85.8984 1.25263 86.3987 3.11956 85.5557 4.57942L82.2996 10.2192C81.7347 11.1984 80.7083 11.7459 79.6533 11.7459Z"
//         fill="#29CCB1"
//       />
//       <path
//         d="M20.8433 87.8841V103.961H14.7381V87.8841C14.7381 86.1992 16.1058 84.8315 17.7907 84.8315C19.4756 84.8315 20.8433 86.1992 20.8433 87.8841Z"
//         fill="#73C3FF"
//       />
//       <path
//         d="M95.9366 28.6645H89.4244C87.7387 28.6645 86.3719 27.2976 86.3719 25.6119C86.3719 23.9262 87.7387 22.5593 89.4244 22.5593H95.9366C97.6224 22.5593 98.9892 23.9262 98.9892 25.6119C98.9892 27.2976 97.6226 28.6645 95.9366 28.6645Z"
//         fill="#274972"
//       />
//       <path
//         d="M50.3514 28.6645H43.8392C42.1535 28.6645 40.7867 27.2976 40.7867 25.6119C40.7867 23.9262 42.1535 22.5593 43.8392 22.5593H50.3514C52.0372 22.5593 53.404 23.9262 53.404 25.6119C53.404 27.2976 52.0376 28.6645 50.3514 28.6645Z"
//         fill="#274972"
//       />
//       <path
//         d="M60.1228 11.7459C59.0676 11.7459 58.0418 11.1985 57.4765 10.2192L54.2204 4.57948C53.3775 3.11962 53.8778 1.2525 55.3376 0.409729C56.7975 -0.433239 58.6646 0.0670576 59.5074 1.52692L62.7635 7.16668C63.6064 8.62654 63.1061 10.4937 61.6463 11.3364C61.1657 11.6141 60.6406 11.7459 60.1228 11.7459Z"
//         fill="#274972"
//       />
//       <path
//         d="M79.6533 11.7459C79.1353 11.7459 78.6105 11.614 78.1299 11.3364C76.67 10.4934 76.1697 8.62648 77.0127 7.16662L80.2688 1.52685C81.1116 0.066994 82.9787 -0.433709 84.4385 0.409666C85.8984 1.25263 86.3987 3.11956 85.5557 4.57942L82.2996 10.2192C81.7347 11.1984 80.7083 11.7459 79.6533 11.7459Z"
//         fill="#274972"
//       />
//       <path
//         d="M20.8433 87.8841V103.961H14.7381V87.8841C14.7381 86.1992 16.1058 84.8315 17.7907 84.8315C19.4756 84.8315 20.8433 86.1992 20.8433 87.8841Z"
//         fill="#73C3FF"
//       />
//       <path
//         d="M30.8151 45.72H4.76655C3.08163 45.72 1.71399 47.0876 1.71399 48.7725V100.87C1.71399 102.557 3.08163 103.922 4.76655 103.961H24.3029C28.0453 103.922 31.2912 101.763 32.8604 98.6249C33.5055 97.3408 33.8677 95.8898 33.8677 94.3574V48.7725C33.8677 47.0874 32.5 45.72 30.8151 45.72Z"
//         fill="#D6DF22"
//       />
//       <path
//         d="M20.8433 87.6805V103.961H14.7381V87.6805C14.7381 85.9956 16.1058 84.6279 17.7907 84.6279C19.4756 84.6279 20.8433 85.9956 20.8433 87.6805Z"
//         fill="#C7D01C"
//       />
//     </g>
//     <defs>
//       <clipPath id="clip0">
//         <rect width="104" height="104" fill="white" />
//       </clipPath>
//     </defs>
//   </svg>
// );

// const fastIcon = () => (
//   <svg
//     width="50"
//     height="50"
//     viewBox="0 0 104 104"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <g clip-path="url(#clip0)">
//       <path
//         d="M0.892308 94.4487C-0.297599 93.2588 -0.297599 91.3309 0.892308 90.141L19.6412 71.4764C20.8311 70.2865 22.7589 70.2865 23.9488 71.4764C25.1387 72.6663 25.1387 74.5942 23.9488 75.7841L5.19998 94.4487C4.00987 95.6386 2.08221 95.6386 0.892308 94.4487Z"
//         fill="#8995B8"
//       />
//       <path
//         d="M0.892308 77.2179C-0.297599 76.028 -0.297599 74.1002 0.892308 72.9103L11.0258 62.858C12.2157 61.6681 14.1436 61.6681 15.3335 62.858C16.5234 64.0479 16.5234 65.9758 15.3335 67.1657L5.19998 77.2179C4.00987 78.408 2.08221 78.408 0.892308 77.2179Z"
//         fill="#8995B8"
//       />
//       <path
//         d="M9.51053 103.067C8.32063 101.877 8.32063 99.9489 9.51053 98.759L28.2563 80.0914C29.4462 78.9015 31.3741 78.9015 32.564 80.0914C33.7539 81.2813 33.7539 83.2092 32.564 84.3991L13.8182 103.067C12.6281 104.257 10.7006 104.257 9.51053 103.067Z"
//         fill="#8995B8"
//       />
//       <path
//         d="M26.7409 103.067C25.551 101.877 25.551 99.9488 26.7409 98.7589L36.8744 88.7066C38.0643 87.5167 39.9922 87.5167 41.1821 88.7066C42.372 89.8965 42.372 91.8244 41.1821 93.0143L31.0486 103.067C29.8585 104.257 27.9306 104.257 26.7409 103.067Z"
//         fill="#8995B8"
//       />
//       <path
//         d="M48.7402 86.5638C47.4906 86.0899 46.3273 85.4436 45.3366 84.4529L19.4884 58.6042C18.4975 57.6134 17.8514 56.4501 17.3775 55.2007L18.8424 50.8928L54.8579 46.5847L54.5132 49.4279L50.5494 82.2555L48.7402 86.5638Z"
//         fill="#274972"
//       />
//       <path
//         d="M103.453 31.8714L99.145 33.6806L72.0904 37.9688L65.9727 31.8714L66.0157 9.14741L72.0471 0.53125C73.3396 0.962281 74.5029 1.60862 75.4935 2.59927L101.342 28.4477C102.333 29.4384 102.979 30.622 103.453 31.8714Z"
//         fill="#274972"
//       />
//       <path
//         d="M103.464 31.8822C89.582 31.882 80.7548 31.8822 65.9846 31.8822L48.7517 49.0952C48.7517 53.0872 48.7515 47.4901 48.7515 86.5746C52.3675 87.8557 56.0122 86.7022 58.2591 84.4515L101.341 41.3724C103.59 39.1236 104.752 35.5163 103.464 31.8822Z"
//         fill="#275489"
//       />
//       <path
//         d="M58.2592 84.4517L79.8006 62.9113L57.3683 40.479L48.7519 49.0954C48.7519 53.0872 48.7515 47.4901 48.7515 86.5748C52.3676 87.8557 56.012 86.7021 58.2592 84.4517Z"
//         fill="#1A3A62"
//       />
//       <path
//         d="M62.9574 55.2008C58.0462 55.2008 53.3933 55.2006 48.7409 55.2006H17.3776C16.0851 51.5815 17.2053 47.963 19.4885 45.6799L62.5694 2.5991C64.8097 0.358831 68.6439 -0.632012 72.0472 0.531081V31.9145C72.0903 36.8689 72.0472 34.7375 72.0903 46.0679C72.0903 51.0647 67.9545 55.2006 62.9574 55.2008Z"
//         fill="#D6DF22"
//       />
//       <path
//         d="M62.9572 55.2006C65.4562 55.2006 67.7389 54.1669 69.3979 52.508L41.0288 24.1392L19.4886 45.6802C17.2053 47.9631 16.0853 51.582 17.3775 55.201H48.7406C53.3932 55.2006 58.0458 55.2006 62.9572 55.2006Z"
//         fill="#C7D01C"
//       />
//     </g>
//     <defs>
//       <clipPath id="clip0">
//         <rect width="104" height="104" fill="white" />
//       </clipPath>
//     </defs>
//   </svg>
// );

// const createIcon = (componentKey, color, key, size = "lg") => {
//   if (componentKey in icons) {
//     return React.createElement(icons[componentKey], {
//       key,
//       color,
//       size,
//     });
//   }

//   return null;
// };

// const icons = {
//   secureIcon,
//   simpleIcon,
//   fastIcon,
// };

const IndexLayout = ({ gridItems, subtitle, title, children }) => {
  const getIconHandler = (name) => {
    switch (name) {
      case "secureIcon":
        return <LockSvg />;
      case "simpleIcon":
        return <LikeSvg />;
      case "fastIcon":
        return <StarSvg />;

      default:
        return null;
    }
  };

  return (
    <>
      <Wrapper>
        <TextAlign>
          <ReactGrid>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} sx={{ mb: 4 }}>
              <ChakraGridItem
                colStart={{ base: 1, lg: 3 }}
                colEnd={{ base: 13, lg: 11 }}
              >
                <TitleWrapper>
                  {subtitle && <H4>{subtitle}</H4>}
                  {title && <H2>{title}</H2>}
                </TitleWrapper>
              </ChakraGridItem>
            </Grid>

            <SimpleGrid
              columns={{ base: 1, lg: 3 }}
              spacingX="40px"
              spacingY="20px"
            >
              {gridItems &&
                gridItems?.map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "30px",
                      background: "white",
                      borderRadius: "8px",
                    }}
                    alignItems={{ base: "center", md: "flex-start" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        w: "72px",
                        h: "72px",
                        borderRadius: "4px",
                        background: "rgba(48, 81, 120, 0.1)",
                        mb: "48px",
                      }}
                    >
                      {getIconHandler(item?.iconName)}
                    </Box>

                    <Heading
                      as="h5"
                      fontSize={{ base: "18px", md: "24px", lg: "32px" }}
                      sx={{ color: "#305178", fontWeight: 600, mb: "32px" }}
                    >
                      {item?.title}
                    </Heading>

                    <Text
                      fontSize={{ base: "16px", md: "18px" }}
                      sx={{ color: "#0F1F33" }}
                      textAlign={{ base: "center", md: "left" }}
                    >
                      {item?.subtitle}
                    </Text>
                  </Box>
                ))}
            </SimpleGrid>
          </ReactGrid>
        </TextAlign>
      </Wrapper>
      <AppScreenWrapper>{children}</AppScreenWrapper>
    </>
  );
};

IndexLayout.defaultProps = {
  gridItems: [],
};

IndexLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ),
};

export default IndexLayout;
