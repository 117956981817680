import React, { useState, useEffect, useRef } from "react";
import Button from "@cashremit/button/Button";
import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Button as ChakraButton,
} from "@chakra-ui/react";
import DeliverySvg from "assets/svg/DeliverySvg";
import ExchangeRateSvg from "assets/svg/ExchangeRateSvg";
import TransferFeeSvg from "assets/svg/TransferFeeSvg";
import CustomCountryDDNew from "./CustomCountryDDNew";
import { Link } from "react-router-dom";

const CurrencyConverterNew = (props) => {
  const {
    from,
    to,
    defaultFromValue,
    bonus: bonusArr = [],
    defaultToValue,
    // multiCurrency,
    // user,
  } = props;

  const [fromCountry, setFromCountry] = useState(defaultFromValue);
  const [toCountry, setToCountry] = useState(defaultToValue);
  const [fromAmount, setFromAmount] = useState(props.defaultAmount || 1000);
  const [rate, setRate] = useState(props.rate);
  const [bonus, setBonus] = useState(0);
  const [toAmount, _toAmount] = useState(1000 * rate);
  const [fee, setFee] = useState(1);

  const inp = useRef("from");

  const setToAmount = (amt) => {
    _toAmount(amt < 0 ? 0 : amt);
  };

  useEffect(() => {
    setRate(props.rate);
    setToAmount((+fromAmount - +fee) * props.rate);
    feesCalculation(fromAmount);
    bonusRateCalculation(fromAmount - +fee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rate, props.fees, props.bonus, props.secondaryCurrency]);

  useEffect(() => {
    switch (inp.current) {
      case "to":
        setFromAmount(toAmount / rate + fee);
        break;
      case "from":
        setToAmount((fromAmount - fee) * rate);
        break;

      default:
        break;
    }

    return () => {};
  }, [fee, fromAmount, rate, toAmount]);

  const feesCalculation = (amt) => {
    bonusRateCalculation(amt);
    const { fees } = props;
    for (const f of fees) {
      if (amt > f.from && amt <= f.to) {
        setFee(f.rate);
        return;
      }
    }
  };

  // console.log("fee", fee, typeof fee);

  const bonusRateCalculation = (amt) => {
    for (const b of bonusArr) {
      const { range, rate: bonusRate = 0 } = b;
      const { start, end } = range || { start: 0, end: 0 };
      if (amt >= start && amt < end && bonusRate > rate) {
        setBonus(+amt * (+bonusRate - +rate));
        return;
      }
    }
    setBonus(0);
  };

  const amountChangeHandler = (e, w) => {
    switch (w) {
      case "from":
        inp.current = "from";
        feesCalculation(e.target.value);
        bonusRateCalculation(e.target.value - fee);
        setFromAmount(e.target.value);
        setToAmount((e.target.value - fee) * rate);

        break;
      case "to":
        inp.current = "to";
        feesCalculation(e.target.value / rate);
        bonusRateCalculation(e.target.value / rate - fee);
        setToAmount(e.target.value);
        setFromAmount(e.target.value / rate + fee);

        break;
      default:
        break;
    }
  };

  const countryChangeHandler = (v, w) => {
    switch (w) {
      case "from":
        setFromCountry(v);
        props.onCountryChange({ from: v.value, to: toCountry.value });
        break;
      case "to":
        setToCountry(v);
        props.onCountryChange({ from: fromCountry.value, to: v.value });
        break;
      default:
        break;
    }
  };

  const [isEditing, setIsEditing] = useState(false);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat(props.country || "en-AU", {
      style: "decimal",
      maximumFractionDigits: 2,
    });

    return formatter.format(number);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "32px",
          background: "white",
          boxShadow: "0px 16px 60px -1px #6F8CAD1A",
          borderRadius: "16px",
          border: "1px solid #dee3e9",
        }}
        w={{ base: "100%", lg: "fit-content" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Text
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                color: "#305178",
                mb: "16px",
                opacity: "80%",
              }}
            >
              You Send
            </Text>

            <InputGroup size="md">
              {isEditing ? (
                <Input
                  pr="10rem"
                  type="number"
                  size="lg"
                  step="0.01"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  defaultValue={fromAmount}
                  name="from"
                  value={fromAmount}
                  onChange={(e) => amountChangeHandler(e, "from")}
                  onBlur={toggleEditing}
                />
              ) : (
                <Input
                  pr="10rem"
                  size="lg"
                  type="text"
                  name="from"
                  value={toCurrency(fromAmount)}
                  onFocus={toggleEditing}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              )}

              {/* <Input pr="10rem" type="number" size="lg" /> */}
              <InputRightElement width="9.5rem" h="100%" sx={{ zIndex: "1" }}>
                <Box
                  sx={{
                    display: "inline-block",
                    h: "100%",
                    w: "100%",
                  }}
                >
                  <CustomCountryDDNew
                    data={from}
                    defaultValue={fromCountry.value}
                    idx="from"
                    onChange={(v) => countryChangeHandler(v, "from")}
                  />
                </Box>
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              my: "24px",
            }}
          >
            <Box sx={{ display: "flex", gap: 10, mb: "10px" }}>
              <ExchangeRateSvg />

              <Heading
                as="h4"
                sx={{
                  color: "#305178",
                  fontWeight: 300,
                  fontSize: "14px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                whiteSpace={{ base: "nowrap", sm: "unset" }}
              >
                Our Exchange Rete:{" "}
                <Text sx={{ fontWeight: 400, ml: 1 }}>
                  {fromCountry.name} 1 = {`${toCountry.name} ${rate}`}
                </Text>
              </Heading>
            </Box>

            <Box sx={{ display: "flex", gap: 10, mb: "10px" }}>
              <DeliverySvg />

              <Heading
                as="h4"
                sx={{
                  color: "#305178",
                  fontWeight: 300,
                  fontSize: "14px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                whiteSpace={{ base: "nowrap", sm: "unset" }}
              >
                Delivery Time:{" "}
                <Text sx={{ fontWeight: 400, ml: 1 }}>0-30 mins</Text>
              </Heading>
            </Box>

            <Box sx={{ display: "flex", gap: 10, mb: "10px" }}>
              <TransferFeeSvg />

              <Heading
                as="h4"
                sx={{
                  color: "#305178",
                  fontWeight: 300,
                  fontSize: "14px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                whiteSpace={{ base: "nowrap", sm: "unset" }}
              >
                Our Transfer Fees:{" "}
                <Text sx={{ fontWeight: 400, ml: 1 }}>{`${fee ? fee : "0.00"} ${
                  fromCountry.name
                } Estimated`}</Text>
              </Heading>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // mb: "32px",
            }}
          >
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#305178",
                mb: "16px",
                opacity: "80%",
              }}
            >
              Recipient gets
            </Text>

            <InputGroup size="md">
              {isEditing ? (
                <Input
                  pr="10rem"
                  type="number"
                  size="lg"
                  step="0.01"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  defaultValue={toAmount}
                  name="to"
                  value={toAmount}
                  onChange={(e) => amountChangeHandler(e, "to")}
                />
              ) : (
                <Input
                  pr="10rem"
                  size="lg"
                  type="text"
                  name="to"
                  value={toCurrency(toAmount)}
                  onFocus={toggleEditing}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              )}

              {/* <Input pr="10rem" type="number" size="lg" /> */}
              <InputRightElement width="9.5rem" h="100%" sx={{ zIndex: "1" }}>
                <Box
                  sx={{
                    display: "inline-block",
                    h: "100%",
                    w: "100%",
                  }}
                >
                  <CustomCountryDDNew
                    data={
                      props.secondaryCurrency && props.secondaryCurrency.length
                        ? props.secondaryCurrency
                        : to
                    }
                    defaultValue={toCountry.value}
                    idx="to"
                    onChange={(v) => countryChangeHandler(v, "to")}
                  />
                </Box>
              </InputRightElement>
            </InputGroup>
          </Box>

          {props.children}

          <Box
            sx={{
              display: "flex",
              gap: 30,
              mt: "32px",
              flexWrap: "wrap",
            }}
            justifyContent={{
              base: "unset",
              lg: "space-between",
            }}
            // flexDirection={{ base: "column", sm: "row" }}
          >
            <Box
              sx={{
                display: "inline-block",
              }}
            >
              <Link to="/dashboard">
                <ChakraButton
                  variant="outline"
                  sx={{
                    h: "100%",
                    border: "1px solid #dee3e9",
                    color: "#1A3350",
                    fontWeight: 500,
                    p: "10px",
                  }}
                  fontSize={{ base: "16px", md: "20px" }}
                  minW={{ base: "140px", md: "190px" }}
                >
                  Add a wallet
                </ChakraButton>
              </Link>
            </Box>

            <Box
              sx={{
                display: "inline-block",
                button: { borderRadius: "8px" },
              }}
            >
              <Button
                width={"0%"}
                fontSize={"20px"}
                fontWeight={"500"}
                onClick={() =>
                  props.onButtonClick({
                    amount: +fromAmount,
                    fee,
                    rate,
                    bonus,
                    receive: +toAmount,
                  })
                }
              >
                {props.buttonText || `View More`}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CurrencyConverterNew;
