import React, { useState, useEffect, useCallback } from "react";
import HomeBanner from "../../@cashremit/home-banner";
import Navbar from "../../@cashremit/TopNav";
import ReferFriend from "../../@cashremit/refer-friend";
import Footer from "../../@cashremit/footer";
import { authAPI, countryAPI } from "../../http/api";
import {
  MainWrapper,
  Section,
  FAB,
} from "../../containers/globals/layout";
import {
  data,
  brandImg,
  demoData,
  appdemo,
  demoapps,
  streamlineIcons,
  links,
  auth_links,
  brandImgMobile,
  deliveryMethodFor,
  deliveryOptions,
} from "../Landing/data";
import IndexLayout from "../../containers/IndexLayout/indexLayout";
import AppScreen from "../../containers/IndexLayout/AppScreen";
//import CookAlertBanner from "../../containers/CookiesAlertBanner";
import Alert from "../../containers/Alert";
import moment from "moment";
import BankingSecurity from "./BankingSecurity";
import {
  Box,
  Image,
  Text,
  Heading as ChakraHeading,
  GridItem,
  Grid as ChakraGrid,
} from "@chakra-ui/react";
import { Grid } from "react-flexbox-grid";
import CurrencyConverterNew from "@cashremit/currency-converter-new/CurrencyConverterNew";
import DeliveryMethodNew from "@cashremit/currency-converter-new/DeliveryMethodNew";
const HomePage = (props) => {
  const [rate, setRate] = useState(5);
  const [fees, setFees] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [fromCountryCode, setFromCountryCode] = useState("14");
  const [toCountryCode, setToCountryCode] = useState("164");
  const [user, setUser] = useState();
  const [country, setCountry] = useState({
    from: [
      {
        name: "AUD",
        value: "14",
        flag: `https://cashremit-contryflag.s3.us-west-2.amazonaws.com/svg/au.svg`,
      },
    ],
    to: [
      {
        name: "NGN",
        value: "164",
        flag: `https://cashremit-contryflag.s3.us-west-2.amazonaws.com/svg/ng.svg`,
      },
    ],
    secondaryTo: [],
  });
  const [multiCurrency, setMultiCurrency] = useState([]);
  // const [secondaryCurrency, setSecondaryCurrency] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState("ACCOUNT");

  const onCountryChange = (values) => {
    const nFrom = from.find((f) => f.value === +values.from);
    let nTo = to.find((t) => t.value === +values.to);
    if (!nTo) {
      countryAPI
        .getCountryByCode(values.to)
        .then(({ data }) => {
          let secondaryTo =
            data.data.map((sc) => ({
              ...sc,
              value: `${sc.value}`,
              flag: `https://cashremit-contryflag.s3.us-west-2.amazonaws.com/png/100/${sc.country.flag_img}`,
            })) || [];
          setCountry((c) => ({ ...c, secondaryTo }));
          // setSecondaryCurrency(data.data)
          nTo = data.data[0];
          getCurrencyRate({
            from: nFrom.name,
            to: nTo.name,
            to_country_id: nTo.value,
            from_country_id: nFrom.value,
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    } else {
      // setSecondaryCurrency([]);
      setCountry((c) => ({ ...c, secondaryTo: [] }));
      setFromCountryCode(values.from);
      setToCountryCode(values.to);
      getCurrencyRate({
        from: nFrom.name,
        to: nTo.name,
        to_country_id: nTo.value,
        from_country_id: nFrom.value,
      });
    }
  };

  const getCurrencyRate = (params = {}) => {
    countryAPI
      .currencyConverter(params)
      .then(({ data }) => {
        setRate(data.rate);
        setFees(data.fee); //remove this comment, when latest code of currency converter published
        setMultiCurrency([
          ...(data.multiCurrency ? [{ ...data.multiCurrency }] : []),
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUser = useCallback(() => {
    authAPI
      .validateToken(localStorage.getItem("utoken"))
      .then(({ data }) => {
        const userData = data.data;
        userData.dob = userData.dob ? moment(userData.dob) : null;
        userData.expiry_date = userData.expiry_date
          ? moment(userData.expiry_date)
          : null;
        setUser(userData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    const getCountryList = () => {
      countryAPI
        .getCountryList()
        .then(({ data }) => {
          setFrom(data.from);
          setTo(data.to);
          const list = {
            from: data.from.map((c) => ({
              ...c,
              value: `${c.value}`,
              flag: `https://cashremit-contryflag.s3.us-west-2.amazonaws.com/svg/${
                c.country.flag_img.split(".")[0]
              }.svg`,
            })),
            to: data.to.map((c) => ({
              ...c,
              value: `${c.value}`,
              flag: `https://cashremit-contryflag.s3.us-west-2.amazonaws.com/svg/${
                c.country.flag_img.split(".")[0]
              }.svg`,
            })),
          };
          setCountry(list);
        })
        .catch((err) => console.log(err));
    };
    getCurrencyRate();
    getCountryList();
    return () => {};
  }, []);

  const getLinks = (data) => {
    const links = data.links.map((link, index) => (
      <a key={index} href={link.href}>
        {link.title}
      </a>
    ));
    return links;
  };

  const iconGridItems = () =>
    streamlineIcons.map((grid) => ({
      ...grid,
    }));

  return (
    <MainWrapper>
      <Section disablePaddingTop>
        <HomeBanner
          isbgImage={true}
          brandImg={brandImg}
          brandImgMobile={brandImgMobile}
          heading="GLOBAL MONEY TRANSFER"
          title="Seamless Money Transfer"
          description="We provide unbeatable exchange rates for most currencies around the world, delivered with convenience and customer satisfaction in mind. with best fixed fees and a great customer service.."
          cta="Get Started"
          topNavComp={
            <Navbar
              links={user ? links.filter((l) => l.title !== "Login") : links}
              user={user}
              auth_links={
                user
                  ? [
                      {
                        title: "Account",
                        href: "/settings",
                      },
                    ]
                  : auth_links
              }
              isBgWhite={false}
            />
          }
        >
          <>
            <CurrencyConverterNew
              from={country.from}
              to={country.to}
              fees={fees}
              rate={rate}
              defaultFromValue={{
                name: "AUD",
                value: `${fromCountryCode}` || "14",
              }}
              defaultToValue={{
                name: "NGN",
                value: `${toCountryCode}` || "164",
              }}
              buttonText="Send Money"
              multiCurrency={multiCurrency}
              secondaryCurrency={country.secondaryTo}
              onButtonClick={({ amount }) => {
                localStorage.setItem(
                  "cc",
                  JSON.stringify({
                    from: from.find((f) => f.value === +fromCountryCode),
                    to: to.find((t) => t.value === +toCountryCode),
                    deliveryMethod,
                    amount,
                  })
                );
                props.history.push(`/send-money`);
              }}
              onCountryChange={onCountryChange}
              user={user}
            >
              {deliveryMethodFor.includes(toCountryCode) && (
                <DeliveryMethodNew
                  defaultValue={deliveryMethod}
                  options={deliveryOptions}
                  onChange={setDeliveryMethod}
                />
              )}
            </CurrencyConverterNew>
          </>
        </HomeBanner>
      </Section>

      <Section
        className="refer_friend"
        classToggle={deliveryMethodFor.includes(toCountryCode)}
      >
        <ReferFriend classToggle={deliveryMethodFor.includes(toCountryCode)} />
      </Section>

      <Section>
        <IndexLayout
          title="Why choose CashRemit Transfer?"
          subtitle="Your transfer is safe with us"
          gridItems={iconGridItems()}
        >
          <AppScreen
            title={demoData[0].title}
            subtitle={demoData[0].subtitle}
            heading={demoData[0].heading}
            appdemo={appdemo}
            downloadapp={demoapps}
          />
        </IndexLayout>
      </Section>

      <Section>
        <BankingSecurity />
      </Section>

      <Section>
        <Grid>
          <ChakraGrid templateColumns="repeat(12, 1fr)" gap={4} sx={{ mt: 10 }}>
            <GridItem
              colStart={{ base: 1, lg: 3 }}
              colEnd={{ base: 13, lg: 11 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ChakraHeading
                  as="h5"
                  fontSize={{ base: "18px", md: "20px" }}
                  sx={{
                    color: "#305178",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    mb: "28px",
                  }}
                >
                  CashRemit Global Reach
                </ChakraHeading>
                <ChakraHeading
                  as="h2"
                  fontSize={{ base: "28px", md: "28px", xl: "56px" }}
                  sx={{
                    color: "#305178",
                    fontWeight: 700,
                    textTransform: "uppercase",
                    mb: "18px",
                  }}
                >
                  Countries we served
                </ChakraHeading>
                <Text
                  fontSize={{ base: "14px", md: "16px" }}
                  sx={{
                    color: "#0F1F33",
                    fontWeight: 400,
                    mb: "60px",
                    textAlign: "center",
                  }}
                >
                  We continually strive to expand our reach and ensure that our
                  services are accessible to customers around the world.
                </Text>
              </Box>
            </GridItem>
          </ChakraGrid>
        </Grid>

        <Box sx={{ display: "inline-block", w: "100%" }}>
          <Grid>
            <Image
              src="/assets/countries1.png"
              alt=""
              boxSize="100%"
              objectFit="cover"
            />
          </Grid>
        </Box>
      </Section>

      <Section disablePaddingBottom>
        <Footer
          socialLinks={data.links_social_icons}
          contactTitle={data.contactTitle}
          phone={data.phone}
          email={data.email}
          columnOneLinks={getLinks(data.links_column_one)}
          columnOneTitle={data.links_column_one.title}
          columnTwoLinks={getLinks(data.links_column_two)}
          columnTwoTitle={data.links_column_two.title}
          columnThreeLinks={getLinks(data.links_column_three)}
          columnThreeTitle={data.links_column_three.title}
        />
      </Section>

      <div style={{ position: "relative" }}>
        <FAB>
          <Alert />
        </FAB>
      </div>
    </MainWrapper>
  );
};

export default HomePage;
