import axios from "../axios";

const settingExport = {
  getProfileData() {
    return axios.get("/users/profile");
  },
  addCloseAccountRequest(params) {
    return axios.post("/users/support/ticket", params);
  },

  // doc verification
  getCountryCodes(configurationName = "Identity Verification") {
    return axios.post("/users/trulioo/countrycodes", { configurationName });
  },
  updateProfileData(payload) {
    const formdata = new FormData();
    const { proof_id, proof_address, ...reqData } = payload;
    Object.keys(reqData).map((k) =>
      formdata.append(k, reqData[k])
    );
    if (proof_id) formdata.append("proof_id", proof_id);
    if (proof_address) formdata.append("proof_address", proof_address);
    return axios.put("/users/profile", formdata);
  },
  documentVerification(payload = {}) {
    return axios.post("/users/trulioo/verify", payload);
  },
};

export default settingExport