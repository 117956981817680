import axios from "../axios";

const paymentExport = {
  getPaymentMethodList(query) {
    return axios.get(`/users/payment/method?${query}`);
  },
  getReasonsForSend() {
    return axios.get("/users/payment/reason_for_send");
  },
  postPaymentSummary(params) {
    return axios.post("/users/payment/summary", params);
  },
  attachRecipentToPayment(params) {
    return axios.put("/users/payment/recipient", params);
  },
  attachReasonToPayment(params) {
    return axios.put("/users/payment/reason_for_send", params);
  },

  // poli payment
  initiatePaymentPoli(params) {
    return axios.post("/users/poli/initiate", params);
  },
  getTransaction(token, isKaasi = false) {
    return axios.post("/users/poli/transaction", { token, isKaasi });
  },

  // Assembly Payment
  getNPPDetails(txId) {
    return axios.get(`/users/assembly/npp/${txId}`);
  },

  // send money - All in 1
  sendMoney(params) {
    return axios.post("/users/payment/send-money", params);
  },

  // send money - get account status - for new account
  getAccountStatus(params) {
    return axios.post("/users/payment/get-account-status", params);
  },

  // Create Agreement - Payto
  createPayToAgreement(params = {}) {
    return axios.post("/users/payment/agreement", params);
  },

  // Get Payment Id
  getPaymentID() {
    return axios.get("/users/wallet/payid-details");
  },

  uploadReceipt(params) {
    const formdata = new FormData();
    const { receipt, ...reqData } = params;
    Object.keys(reqData).map((k) => formdata.append(k, reqData[k]));
    if (receipt) formdata.append("receipt", receipt);
    return axios.post("/users/payment/upload-receipt", formdata);
  },

  getPayToAggrementDetails(aggrement_uuid) {
    return axios.get("/users/payment/payto/aggrement/" + aggrement_uuid);
  },

  // enable2fa
  enable2fa(params) {
    return axios.put(`/users/profile/enable2fa`, params);
  },

  // 2fa-code
  twofaCode() {
    return axios.get(`/users/profile/2fa-code`);
  },
};

export default paymentExport